import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  }, {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  }, {
    path: 'ver-mascota',
    loadChildren: () => import('./modules/see-pet/see-pet.module').then(m => m.SeePetModule)
  }, {
    path: 'registro',
    loadChildren: () => import('./modules/registry/registry.module').then(m => m.RegistryModule)
  }, { 
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
