import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [LoginService]
})
export class NavbarComponent implements OnInit {

  currentUser;
  open = false;
  menu = '/';

  constructor(private loginService: LoginService, private router: Router) {
    router.events.subscribe(() => {
      this.menu = this.router.url;
    });
  }

  ngOnInit(): void {
    this.currentUser = this.loginService.currentUserValue;
  }

  openClose() {
    this.open = !this.open;
  }

  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  logout() {
    this.currentUser = null;
    this.loginService.logout();
  }

}
