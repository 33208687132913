import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CryptoService } from '../../services/crypto/crypto.service';
import { Veterinary } from 'src/app/models/veterinaty';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(
    private http: HttpClient,
    private crypto: CryptoService,
    private router: Router,
  ) { }

  public get currentUserValue() {
    const token = localStorage.getItem('dataNanimales');
    if (!token) {
      return null;
    }
    return this.crypto.get(token);
  }

  login(data): Observable<any> {
    return this.http.post<any>(environment.apiServer + '/veterinary/login', data, { observe: 'response', headers: this.headers }).pipe(map(user => user));
  }

  logout() {
    localStorage.removeItem('dataNanimales');
    this.router.navigate(['/']);
  }

  create(veterinary: Veterinary) {
    let params = JSON.stringify(veterinary);
    return this.http.post(environment.apiServer + '/veterinary/signup', params, { headers: this.headers });
  }

  recoverPassword(user_email: string) {
    return this.http.post<any>(environment.apiServer + '/veterinary/password_recovery', { email: user_email }, { observe: 'response', headers: this.headers });
  }

  getGeneralHeaders() {
    if (this.currentUserValue) {
      return {
        headers: new HttpHeaders().set
        ('token', this.currentUserValue.token).set
        ('Content-Type', 'application/json')
      };
    }
    return {
      headers: new HttpHeaders().set
      ('Content-Type', 'application/json')
    };
  }

  headersToFiles(): {headers: HttpHeaders} {
    if (this.currentUserValue) {
      return {
        headers: new HttpHeaders().set
        ('token', this.currentUserValue.token)
      };
    }
  }

  getInfoUserToSave(token): Observable<any> {
    return this.http.get<any>(environment.apiServer + '/users/profile', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) }).pipe(map(res => res));
  }

}
