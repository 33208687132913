export const environment = {
  production: true,
  apiServer: 'https://us-central1-nanimales-admin.cloudfunctions.net/api',
  cryptoKey: 'MnsFXGNsqBGskgRUa3QPJm9zHThxcb',
  firebaseConfig: {
    apiKey: 'AIzaSyDk8gz9p4fHNVis35wyIRIbYgMhMeGEdBQ',
    authDomain: 'nanimales-admin.firebaseapp.com',
    databaseURL: 'https://nanimales-admin.firebaseio.com',
    projectId: 'nanimales-admin',
    storageBucket: 'nanimales-admin.appspot.com',
    messagingSenderId: '667538728465',
    appId: '1:667538728465:web:b02902080f3aa9581df3b0',
    measurementId: 'G-3Y566D8957'
  },
};
