import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  @Input() classColor = 'yellow';
  src = '/assets/img/logo.png';

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      if(screen.width > 1100) {
        switch (this.classColor) {
          case 'white':
            this.src = '/assets/img/logo-white.png';
            break;
          case 'blue':
            this.src = '/assets/img/logo-blue.png';
            break;
          case 'yellow':
            this.src = '/assets/img/logo.png';
            break;
          default:
            this.src = '/assets/img/logo.png';
        }
      }
    });
  }

  ngOnInit(): void { }

}
