import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Message } from 'src/app/models/Message';

@Component({
  selector: 'app-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.css']
})
export class MessageAlertComponent implements OnInit, OnChanges {
  @Input() message: Message;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.message = changes.message.currentValue;
  }

  ngOnInit(): void {
  }

  closeMessage(): void {
    this.message = null;
  }
}
