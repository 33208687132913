<div class="content">
  <div class="dropdown">
    <i class="fa fa-bars fa-2x icon-bars" [ngStyle]="{'color': menu !== '/' && menu !== '/login' && menu !== '/registro' ? 'white' : 'black' }" (click)="openClose()" style="cursor: pointer;"></i>
    <div class="dropdown-content" *ngIf="open">
      <a href="/">Home</a>
      <a href="/ver-mascota">Mascotas</a>
      <a (click)="logout()" *ngIf="currentUser">
        Salir <i class="fa fa-sign-out-alt"></i>
      </a>
    </div>
  </div>
</div>