<div id="message" *ngIf="message" (click)="closeMessage()">
  <div id="content_container">
    <div id="content" (click)="$event.stopPropagation()">
      <h2>{{message.title}}</h2>
      <div class="close" (click)="closeMessage()"><i class="fas fa-times-circle"></i></div>
      {{message.body}}
      <div class="buttons-options" *ngIf="message.buttons_options">
        <ng-container *ngFor="let button of message.buttons_options">
          <button class="btn btn-primary type-2" [routerLink]="[button.url.link]" [queryParams]="button.url.queryParams" (click)="button.action()">{{button.title}}</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
