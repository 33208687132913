import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MessageAlertComponent } from '../../components/message-alert/message-alert.component';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { LogoComponent } from '../../components/logo/logo.component';

import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        MessageAlertComponent,
        LoadingScreenComponent,
        NavbarComponent,
        LogoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule
     ],
    exports: [
        ReactiveFormsModule,
        MessageAlertComponent,
        LoadingScreenComponent,
        NavbarComponent,
        LogoComponent
    ]
})
export class SharedModule {}